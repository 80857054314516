import React from "react";
import bagage from "../img/icons/bagages.png";
import tgv from "../img/TGV.png";
import chevronDown from "../img/icons/arrow-down-main-color.svg";
import planeOutBound from "../img/icons/plane-outbound.svg";
import planeInBound from "../img/icons/plane-inbound.svg";
import { useState } from "react";

const FlightSegment = ({ segment, info, type }) => {
  const [isExpanded, setIsExpanded] = useState("false");

  const toggleExpandedAller = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      {/* VOL ALLER */}
      <div
        className={`bg-grey-pagination flex flex-col rounded-t-md custom-border-b max-w-1012 group ${
          !isExpanded ? "is-expanded" : ""
        }`}
      >
        <div className="flex justify-between py-4 pl-6 pr-1.5 rounded-md main-color">
          <div className="flex desktop:items-center tablet:items-center">
            <span className="w-value-50 h-value-50 flex items-center justify-center mr-3">
              {type === "retour" ? (
                <img src={planeInBound} alt="plane" />
              ) : (
                <img src={planeOutBound} alt="plane" />
              )}
            </span>
            <span className="w-value-50 h-value-50 bg-white border border-gray-100 flex items-center justify-center rounded-md mr-3 main-color">
              {segment.company.logo !== "" ? (
                <img src={segment.company.logo} alt={segment.company.name} />
              ) : (
                <span className="w-full h-full flex items-center justify-center font-bold">
                  {segment.company.name}
                </span>
              )}
            </span>
            <div className="flex desktop:items-center tablet:flex-col">
              <div className="desktop:w-value-230 tablet:mb-2.5 flex flex-col whitespace-nowrap">
                <div className="flex text-fs-14">
                  <span className="font-bold">{segment.date}</span>
                  <span className="ml-value-24">
                    {segment.from_time}/ {segment.to_time}
                    {segment.lag === 1 ? " (J+1)" : ""}
                  </span>
                </div>
                <div className="flex mt-value-5">
                  <span className="text-fs-13 leading-lh-15">
                    {segment.company.name}
                  </span>
                  {segment.last_ticket_dates.length > 0 && (
                    <span className="text-red text-fs-12 ml-1.5 leading-lh-16">
                      (Émission av. le {segment.last_ticket_dates})
                    </span>
                  )}
                </div>
              </div>
              <div className="desktop:w-value-75 tablet:mb-2.5 flex flex-col smalldesktop:ml-2.5 desktop:ml-value-80 mt-value-5">
                <span className="font-bold text-fs-14">{segment.duration}</span>
                <span className="text-fs-13 mt-value-5">
                  {segment.from}/{segment.to}
                </span>
              </div>
              <span className="desktop:w-value-60 tablet:mb-2.5 font-bold text-fs-14 desktop:ml-value-50 smalldesktop:!ml-2.5 self-start desktop:mt-value-5 text-center">
                {segment.stops > 1
                  ? `${segment.stops} Escales`
                  : segment.stops === 1
                  ? `1 Escale`
                  : "Direct"}
                <br />
                {segment.change_aireport === true
                  ? `Changement d’aéroport`
                  : ""}
              </span>
              <span className="desktop:w-value-80 tablet:mb-2.5 font-bold text-fs-14 desktop:ml-value-50 smalldesktop:!ml-2.5 self-start desktop:mt-value-5">
                {segment.class}
              </span>
              <div className="desktop:w-value-70 flex flex-col smalldesktop:!ml-2.5 desktop:ml-value-87">
                <div className="flex">
                  <img
                    src={bagage}
                    alt=""
                    className="max-w-17 mr-1.5 self-center"
                  />
                  <span className="font-bold text-fs-14">Bagages</span>
                </div>
                <span className="text-fs-13 mt-value-5 leading-lh-15">
                  {/* {segment.baggage === true ? "" : "non "} */} inclus
                </span>
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <span
              className="cursor-pointer p-2.5"
              onClick={toggleExpandedAller}
            >
              <img
                className="group-[.is-expanded]:rotate-180"
                src={chevronDown}
                alt=""
              />
            </span>
          </div>
        </div>

        {/* ACCORDION */}
        <div className="bg-white group-[.is-expanded]:flex hidden rounded-md flex-col mr-3 ml-value-87 mb-4 px-2.5 py-value-24 relative">
          {info[type].map((item, index) => {
            return item.type === "segment" ? (
              <div className="flex items-center" key={index}>
                <span className="w-value-40 h-value-40 bg-white border border-gray-100 flex items-center justify-center rounded-md mr-5 main-color">
                  {item.air_equip_type !== "TRN" ? (
                    item.company.logo !== "" ? (
                      <img
                        src={segment.company.logo}
                        alt={segment.company.name}
                      />
                    ) : (
                      <span className="w-full h-full flex items-center justify-center font-bold">
                        {item.company.name}
                      </span>
                    )
                  ) : (
                    <img src={tgv} alt="TGV" />
                  )}
                </span>
                <span className="flightseparator"></span>
                <div className="flex flex-col main-color">
                  <div className="flex font-bold text-fs-14">
                    <span className="mr-5">{item.from_time}</span>
                    <span>{item.terminal_from}</span>
                  </div>
                  <span className="text-fs-13 my-2.5">
                    Temps de trajet : {item.duration}
                  </span>
                  <div className="flex font-bold text-fs-14">
                    <span className="mr-5">{item.to_time}</span>
                    <span>{item.terminal_to}</span>
                  </div>
                </div>
                <div className="main-color absolute desktop:right-value-250 tablet:right-2.5 flex tablet:flex-col tablet:text-center text-fs-14">
                  {item.air_equip_type !== "TRN" && (
                    <span className="font-bold desktop:mr-value-5">
                      {item.company.name}
                    </span>
                  )}
                  <span>
                    {item.air_equip_type !== "TRN" ? "Vol n°" : "Train n°"}
                    {item.company.code}
                    {item.flight_number}
                  </span>
                </div>
              </div>
            ) : (
              <div className="py-4 stopover my-value-24" key={index}>
                <span className="text-fs-13 main-color block">
                  Escale de {item.duration}
                  {item.change_aireport !== true && ` à ${item.terminal}`}
                </span>
                {item.change_aireport === true && (
                  <span className="text-fs-13 main-color block font-bold">
                    Changement d’aéroport
                  </span>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default FlightSegment;
